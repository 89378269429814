<template>
  <LoginView>
    <ResetPasswordForm
      :is-loading="isLoading"
      @click:reset="onClickReset"
      @click:back="$router.push({ name: 'login' })"
    />
  </LoginView>
</template>

<script>
import { ref, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import { useRouter } from "vue-router";
// Components
import ResetPasswordForm from "@/views/general/resetPassword/ResetPasswordForm";
import LoginView from "@/views/general/LoginView";

export default {
  components: {
    ResetPasswordForm,
    LoginView
  },
  setup() {
    // Misc
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const toast = inject("toast");

    // Data
    const isLoading = ref(false);

    // Computed
    const documentTitle = computed(() => t("app.reset_password"));

    // Methods
    const onClickReset = async data => {
      isLoading.value = true;

      const { payload } = await store.dispatch("api/request", {
        endpoint: "password.reset",
        toast,
        data
      });

      if (payload.message.status === "Success") {
        const pw = payload?.message?.temp_passsword;
        store.dispatch("user/setTemporaryPassword", pw);
        router.push({
          name: "change-password",
          params: { username: data.username }
        });
      }

      isLoading.value = false;
    };

    return {
      isLoading,
      documentTitle,
      onClickReset
    };
  }
};
</script>
